.footer{
    padding: 50px 0 10px 0;
    margin-top: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    font-family: var(--heading-font);
    z-index: 2;
    color: white;
}
.footer .container .row{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.footer Col{
margin-top: 50px;
}
.footer h2{
    font-size: 20px;
    color: white;
}
.footer ul{
padding-left: 0;
margin-top: 15px;
}
.footer ul li{
    margin-bottom: 10px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    font-weight: 500;
}
 .footer ul ul{
    display: flex;
 }
 .footer ul ul li{
    margin-right: 10px;
 }
.footer ul li a{
    color: white;
} 
.footer p{
    text-align: center;
    color: white;

}

.arrow-top{
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: black;
    bottom:20px;
    right:20px;
     background-color:white;
     box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     cursor:pointer;
}


@media screen and (max-width:950px) {
    .copyright{
        margin-bottom: 50px;
    }
    .arrow-top{
        bottom: 70px;
    }
    .footer .container .row{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:650px) {
    .footer .container .row{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    .footer ul li{
        margin-bottom: 10px;
        font-size: 17px;
        color: rgb(232, 232, 232);
    }
}

