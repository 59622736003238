@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap');
:root{
    --paragraph-font:"Josefin Slab", serif;
    --heading-font:"Josefin Slab", serif;
    --bg-color:rgb(216, 216, 216);
    --price-color:#bc6611;
    
}
*{
    font-family: "Josefin Slab", serif;

}

