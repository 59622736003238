*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    user-select:none;
}
.category-banner{
    max-width: 100%;
    min-height: 10vh;
    max-height: 50vh;
    /* margin-top: 40px; */
}
.category-banner img{
    width: 100%;
    min-height: 10vh;
    max-height: 50vh;
}

li{
    list-style: none;
}
a{
    text-decoration: none;
}
li a{
    color: black;
    text-decoration: none;
    box-shadow: none;
}
Link h2{
    text-decoration: none;
}
a{
    text-decoration: none;
}
li a:hover{
    box-shadow: none;
}
.container{
    margin:0 50px;
}
div a:hover{
 box-shadow: none;
}
div a{
    text-decoration: none;
}

.margin-section{
    margin:50px 100px 20px 100px;
}
.section-name{
    font-size: 30px;
    margin-bottom: 40px;

}
.products-listing{
    max-width:100vw;
    padding:0 0 20px 0;
}
.products-listing .product-name{
    font-weight:500;
    font-size:30px;
    margin-bottom:20px;
}
.products-listing .product-list{
    max-width:100vw;
    display:grid;
    grid-template-columns:repeat(4, 1fr);
    grid-gap:20px;
}
.products-listing .product-list .product-card{
    background-color:white;
    position:relative;
    overflow:hidden;
    border: 1px solid rgb(205, 205, 205);
    transition: all 0.5s;
}

.products-listing .product-list .product-card:active{
    scale: 1.02;

}
.products-listing .product-list .product-card:hover{
    scale: 1.02;

}
.products-listing .product-list .product-card .product-img{
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: white;
}
.products-listing .product-list .product-card .wish-icon{
    position:absolute;
    top:0;
    right:5px;
    top:5px;
    cursor:pointer;
}
.products-listing .product-list .product-card .sold{
    position:absolute;
    left:10px;
    bottom: 0;
    font-size: 14px;
    background-color: rgb(37, 37, 37);
    color: white;
    border-radius: 4px;
    padding: 1px 10px;
    height: 22px;
}
.products-listing .product-list .product-card .product-img img{
    width:100%;
    height: 100%;
    
}
.products-listing .product-list .product-card .product-img .product-img2{
    position: absolute;
    width: 100%;
    top:0;
    left:0;
    opacity:0;
    transition: all 0s;
    background-color: white;
}

.products-listing .product-list .product-card:hover .product-img2{
    opacity:1;
}
.products-listing .product-list .product-card .product-content{
    padding:10px;
}
.products-listing .product-list .product-card P{
    margin-bottom: 5px;
}
.products-listing .product-list .product-card .product-content .title{
    font-size:18px;
    margin-bottom:1px;
    letter-spacing:1px;
    font-weight: 600;
}
.products-listing .product-list .product-card .product-content .price{
    font-weight: 600;
    margin-bottom:0px;
    color: var(--price-color);
}
.products-listing .product-list .product-card .product-content .sale-price{
    font-size:13px;
    color:rgb(52, 52, 52);
    text-decoration:line-through;
    font-weight: 600;

}
.products-listing .product-list .product-card .hover-details{
    width: 100%;
    padding:5px;
    position:absolute;
    bottom:-100%;
    background-color:white;
    transition:all 1s;
    border-radius:5px 5px 0 0;
}
.products-listing .product-list .product-card:hover .hover-details{
    bottom:0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.products-listing .product-list .product-card .wish{
    display:flex;
    justify-content:space-between;

}
.products-listing .product-list .product-card .wish div:nth-child(2){
    display:none;
}
.products-listing .product-list .product-card .wish .cart-icon{
    margin-right:20px;
    font-weight:300;
    cursor:pointer;
    font-size: 18px;
    margin-top: 8px;
}
.products-listing .product-list .product-card .hover-details .title-section{
    display:flex;
    justify-content:space-between;
}
.products-listing .product-list .product-card .hover-details .title-section .title{
    font-size:16px;
    margin-right:30px;
    font-weight: 600;
    margin-bottom: 0;
}
.products-listing .product-list .product-card .hover-details .title-section .price{
    font-weight: 600;
    color: var(--price-color);

}
.products-listing .product-list .product-card .hover-details ul{
    display:flex;
    flex-wrap: wrap;
    padding-left:0;
    grid-gap:5px;
    margin-bottom: 5px;
}
.products-listing .product-list .product-card .hover-details ul li{
    width: auto;
    height: 20px;
    border-radius: 2px;
    padding: 5px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:5px;
    font-size:12px;
    font-weight:500;
    color:grey;
    cursor:pointer;
    border: 1px solid grey;
    margin-top:5px;
}
.products-listing .product-list .product-card .hover-details .size{
    margin-bottom: 2px;
}

.products-listing .product-list .product-card .hover-details .size p{
    margin-bottom:0px;
}
.products-listing .product-list .product-card .hover-details .color p{
    margin-bottom:0px;
}

.products-listing .product-list .product-card .hover-details a{
    color:black;
    width:50%;
 
}
.products-listing .product-list .product-card .hover-details .btns{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    width: 100%;
}
.products-listing .product-list .product-card .hover-details .btns .btn{
    height: 40px;
}
.products-listing .product-list .product-card .hover-details .btns .btn:active{
    scale: 1.01;
}
.products-listing .product-list .product-card .hover-details .btns .btn-disable{
    border: 2px solid white;
    color: white;
    background-color: grey;
    height: 45px;

}
.products-listing .product-list .product-card .hover-details .btns .btn:nth-child(1){
    border: 2px solid rgb(31, 31, 31);
    color: rgb(31, 31, 31);
    background-color: transparent;
}
.products-listing .product-list .product-card .hover-details .btns .btn:nth-child(2){
    border: 2px solid rgb(31, 31, 31);
    color: white;
    background-color: rgb(31, 31, 31);
}
.products-listing .product-list .product-card .hover-details a button{
    width:100%;
    padding:5px 0;
    font-weight:500;
    margin-top: 10px;
}
.collection-listing{
    max-width:100vw;
   
}
.collection-listing .collection-list{
    display:flex;
    overflow-x:scroll;
    overflow-y:hidden;
}
.collection-listing .collection-list::-webkit-scrollbar {
    display: none;
}  
.collection-listing .collection-list .collection-card{
    width:320px;
    margin-right:20px;
    height:320px;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;


}
.collection-listing .collection-list .collection-card img{
    width:320px;
    aspect-ratio:1/1;
}
.collection-listing .collection-list .collection-card p{

    text-align:center;
    font-weight:500;
    font-size:25px;
    color:white;
    text-shadow: 2px 2px 4px #000000;

}
.collection-listing .collection-list .collection-card a{
    width:100%;
}
.collection-listing .collection-list .collection-card button{
    width:100%;
    padding:8px 0;
    font-weight:500;
}
.collection-listing .collection-list .collection-card div{
    position:absolute;
    width:100%;
    height:auto;
    bottom:-100%;
    padding:10px;
    background-color: rgba(6, 6, 6, 0.342);
    transition:all 0.5s;
}
.collection-listing .collection-list .collection-card:hover .collection-content{
    bottom:0;
}
.section-heading{
    font-weight: 600;
    font-size: 30px;
    font-family: var(--heading-font);
    margin-bottom: 20px;
}

.disabled-btn{
    padding: 7px 0;
    margin-bottom: 20px;
    color: white;
    border: 3px solid white;
    background: grey;
    transition: all 0.5s;
}


.skeleton{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 20px 0;
    width: 100%;
}
.skeleton div{
    height: 300px;
    background-color: rgb(205, 205, 205);
    border-radius:3px;
    animation: skelton 1s infinite alternate;}
.skeleton div:nth-child(4){
    display: none;
}
    @keyframes skelton {
        0% {
            background-color: rgb(205, 205, 205); /* Starting color */
        }
        100% {
            background-color: rgb(233, 233, 233); /* Ending color */
        }
    }
    @media screen and (min-width:2100px) {
        .margin-section{
            margin:20px 20vw;
        }
    }    
    

@media screen and (min-width:1700px) {
        .margin-section{
            margin:20px 15vw;
        }
    }    
    
@media screen and (max-width:1450px) {
    .products-listing .product-list{
        /* grid-template-columns:repeat(3, 1fr); */
        grid-gap: 10px;
    }
    .products-listing .product-list .product-card .hover-details .btns .btn{
        font-size: 14px;
    }
    }    
    
@media screen and (max-width:1200px) {
    .margin-section{
        margin:20px 60px;
    }
    .products-listing .product-list .product-card .hover-details .btns .btn{
        font-size: 12px;
    }
}    

@media screen and (max-width:950px){
    .products-listing .product-list{
        grid-template-columns:repeat(2, 1fr);
        grid-gap: 10px;
    }
    .products-listing .product-list .product-card .hover-details{
        display:none;
    }
    .products-listing .product-list .product-card .wish div:nth-child(2){
        display:block;
    }
    .products-listing .product-list .product-card .wish-icon{
        display:none;
    }
    .skeleton{
        grid-template-columns: 1fr 1fr;
    }
    .skeleton div:nth-child(4){
        display: block;
    }
    .margin-section{
        margin:20px 40px;
    }
    /* .category-banner{
        margin-top: 105px;
    } */

    

}
@media screen and (max-width:650px) {
    .margin-section{
        margin:20px 10px;
    }
    .section-heading{
        font-size: 25px;
    }
    
}