.policies{
    max-width: 100vw;
    font-family: var(--heading-font);
}
.policies .policy-heading{
    font-size: 50px;
    letter-spacing: 2px;
    font-family: var(--heading-font);
    text-align: center;
}
.policies .policy{
    margin-top: 20px;
}
.policies .policy p{
    margin-bottom: 15px;
    font-size: 20px;
    word-spacing:7px;
    letter-spacing: 1px;
    font-family: var(--heading-font);
}

@media screen and (max-width:650px) {
    .policies .policy-heading{
        font-size: 35px;
        font-weight: 600;
    }
    .policies .policy p{
        font-size: 18px;
        text-align: left;
    }
}