.homeMain .hero-section .shoes-section {
    max-width: 100vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    position: relative;
}

.homeMain .hero-section img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.homeMain .categories{
    margin-top: 30px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4,1fr);
}
.homeMain .categories .cate{
    /* width:400px; */
    /* height: 380px; */
    /* margin: 0 10px; */
    cursor: pointer;
}
.homeMain .categories .cate img{
    width: 100%;
    height: 100%;
}
.trending-collections{
    margin-top: 50px;
}

@keyframes fadeInOut1 {
    0%, 20% {
      opacity: 1;
    }
    20.1%, 100% {
        opacity: 0;
    }
  }
  
  @keyframes fadeInOut2 {
    0%, 20% {
        opacity: 0;
    }
    20.1%,40%{
        opacity: 1;
    }
    40.1%, 100% {
        opacity: 0;
    }
  }
  
  @keyframes fadeInOut3 {
    0%, 40% {
        opacity: 0;
    }
    40.1%,60%{
        opacity: 1;
    }
    60.1%, 100% {
        opacity: 0;
    }
  }
        @keyframes fadeInOut4 {
            0%, 60% {
              opacity: 0;
            }
            60.1%,80%{
opacity: 1;
            }
            80.1%, 100% {
opacity: 0;            
}
        }
        @keyframes fadeInOut5 {
            0%, 80% {
              opacity: 0;
            }
            80.1%,100%{
opacity: 1;
            }
            
        }
           

.simg1, .simg2, .simg3, .simg4, .simg5 {
    transition: display 1s ease;
  }
  
.hero-section .simg1 {
    animation: fadeInOut1 10s infinite;
  }
  
.hero-section .simg2 {
    animation: fadeInOut2 10s infinite;
  }
.hero-section .simg3 {
    animation: fadeInOut3 10s infinite;
  }
.hero-section .simg4 {
    animation: fadeInOut4 10s infinite;
  }
.hero-section .simg5 {
    animation: fadeInOut5 10s infinite;
  }




.shoes-section {
    margin: 30px 0;
    max-width: 100vw;

}
.shoes-section img{
    width: 100%;
    height: 100%;
}

.icon-footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
}
.icon-footer div{
    text-align: center;
    background-color: white;
    border-radius: 10px;
    padding: 10px 0;
    text-transform: uppercase;
}
.icon-footer img{
    width: 100px;
    height: 100px;
}
@media screen and (max-width:1600px) {
    .homeMain .hero-section .shoes-section{
    height: 60vh;
    }
}
@media screen and (max-width:1400px) {
    .homeMain .hero-section .shoes-section{
        height:55vh;
    }
}
@media screen and (max-width:1200px) {
    .homeMain .hero-section .shoes-section{
    height:50vh;
    }
}
@media screen and (max-width:1000px){

    .homeMain .hero-section .shoes-section{
        height:45vh;
        }

}
@media screen and (max-width:850px){

    .homeMain .hero-section .shoes-section{
        height:35vh;
        }

}
@media screen and (max-width:650px) {
    .homeMain .hero-section .shoes-section{
        height:25vh;
        }

    .trending-collections .trending-collection{
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
    .homeMain .category-devide {
        width: 100vw;
        flex-direction: column;
    }

    .homeMain .category-devide div:hover {
        width: 90%;
        transition: all 0.3s;
    }

    .homeMain .category-devide div {
        width: 100%;
    }

    .shoes-section {

        grid-template-columns: 1fr;
        height: 100%;

    }

    .shoes-section .shoe-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 50px 20px;
        text-align: center;
    }
    .shoes-section .shoe-right p:nth-child(1) {
        font-size: 25px;

    }
    
    .shoes-section .shoe-right p:nth-child(2) {
    
        font-size: 16px;
    }
    .icon-footer{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        text-align: center;
    }
}
@media screen and (max-width:550px){

    .homeMain .hero-section .shoes-section{
        height:20vh;
        }

}