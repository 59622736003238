
.cod{
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 40px 3rem 50px 3rem;
    background-color: white;
    padding:30px 0;
}
.cod .switchs{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cod .lang1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.cod .lang1 .switch{
    color: black;
}
.cod .lang button{
    border: 1px solid grey;
    padding: 8px;
    background-color: white;
    cursor: pointer;
}
.cod .lang .select{
    background-color: rgb(218, 218, 218);
}
.cod .pay-btn{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}
.cod .pay-btn button p{
    margin-bottom: 1px;
}
.cod .pay{
    width: 100%;
    padding: 10px 0;
    font-weight: 500;
    background-color: black;
        border: 3px solid #000000;
        color: white;
    border-radius: 5px;
}
.cod .pay1{
    width: 100%;
    padding: 10px 0;
    font-weight: 500;
    background-color: #ffbc21;
    border: 3px solid #ffbc21;
    color: black;
    border-radius: 5px;
}
.cod .pay:active{
    scale: 1.01;
}
.cod-note{
    padding:0 30px;
}
.cod-note .banner{
    width: 100%;
    margin-bottom: 20px;

}
.cod-note .banner h1{
    font-size: 50px;
    text-align: center;
    margin-top: -10px;
    font-weight: 600;
}
.cod-note .msg{
    margin: 0px 0;
    color: red;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}
.cod-note .msg p{
    text-align: justify;
}
.cod-note .msg .note{
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0;
}

.cod-note .buy{
    text-align: center;
    margin-top: 50px;
}
.cod-note .buy button{
    padding: 8px 15px;
    border-radius: 5px;
    background-color: black;
    color: white;
    border: 2px solid black;
    transition: all 0.5s;

}
.cod-note .buy button:hover{
color: black;
background-color: white;
}

.cod .right{
    border-left: 1px solid grey;
    padding:0 30px;

}
.cod .right .prdt{
    display: grid;
    grid-template-columns: 3fr 1fr 0.3fr;
    justify-content: center;
    height: 100px;
    margin-bottom: 10px;
}
.cod .right .prdt .detail{
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 10px;
    justify-content: center;
}
.cod .right .prdt .detail img{
    width: 80px;
    aspect-ratio: 1/1;
    border: 1px solid grey;
    border-radius: 10px;
}
.cod .right .p-price{
    text-align: right;
    font-weight: 500;
}
.cod .right .p-name{
    font-weight: 500;
    font-size: 14px;
}
.cod .right .size{
    font-weight: 400;
    font-size: 11px;
    margin-top: 2px;
}
.cod .right p{
    margin-bottom: 0;
}
.cod .right .delete{
    margin-left: 10px;

}
.cod .right .delete .delete-icon{
    color: gray;
    font-size: 17px;
    cursor: pointer;
}
.cod .right .coupon{
    display: grid;
    grid-template-columns: 4fr 1fr;
    height: 50px;
    grid-gap: 10px;
}
.cod .right .coupon button{
    border-radius: 10px;
    border: none;
    background-color:var(--price-color);
    color: white;
    font-weight: 500;
}
.cod .right .total{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}
.cod .right .total ul{
    padding-left: 0;
}
.cod .right .total ul li{
    margin-bottom: 10px;

}
.cod .right .total ul:nth-child(2){
    text-align: right;
    font-weight: 500;
}
@media screen and (max-width:1100px) {
    .cod .pay-btn{
        grid-template-columns: 1fr;
    }
}


@media screen and (max-width:950px) {
    .cod{
        grid-template-columns: 1fr;
    }
    .cod-note{
        margin-bottom: 50px;
    }
    .cod .pay-btn{
        grid-template-columns: 1fr 1fr;
    }

    
    .cod .right{
        border-top: 1px solid grey;
        border-left: 0;
        padding: 30px;
    
    }
}
@media screen and (max-width:750px) {
    .cod .pay-btn{
        grid-template-columns: 1fr;
    }
    .cod-note .banner h1{
        font-size: 45px;
    }
}
@media screen and (max-width:650px) {
    .cod{
        margin: 120px 1rem 50px 1rem;
    }

}