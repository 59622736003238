.newlogin{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    height: 100vh;
    border-radius: 5px;
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgb(230, 230, 230);
    margin-top: 40px;
}

.loginpage{
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    width: 60vw;
    height: 80vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


}

.newlogin .loginpage  .background{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    width: 100%;
    position: relative;

}
.newlogin .loginpage  .background img{
    width: 100%;
    height: 80vh;
    border-radius: 10px 0 0 10px;

}
.newlogin .loginpage .background p{
    color: black;
    text-align: center;
    position: relative;
    top: 30vh;
    font-family: "Montserrat", sans-serif;

}
.newlogin .loginpage .background .icon{  
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.726);
    border-radius: 10px 0 0 10px;

}
.newlogin .loginpage .background .icon .logo{
    font-size: 22px;
    letter-spacing: 1px;
    color: white;
    font-family: "Montserrat", sans-serif;

}
.newlogin .loginpage .background .icon p{
    font-size: 15px;
    margin-top: 7px;
    color: black;
    font-family: "Montserrat", sans-serif;

}
.newlogin .loginpage .background .welcome{
    margin-top: 30px;
    font-family: "Montserrat", sans-serif;

}
.newlogin .loginpage .background .welcome .title{
    font-size: 22px;
    letter-spacing: 1px;
    color: black;
    font-family: "Montserrat", sans-serif;

}
.newlogin .loginpage .background .welcome p{
    font-size: 14px;
    margin-top: 7px;
    letter-spacing: 1px;
    color: black;
    font-family: "Montserrat", sans-serif;

}


.newlogin .loginpage .form{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
    position: relative;
    width: 100%;

}


.newlogin .loginpage .form .form-detail{
    text-align: center;
    margin-bottom: 50px;

}
.newlogin .loginpage .form .login{
    font-size: 25px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;

}
.newlogin .loginpage .form .form-detail .register{
    margin-top: 30px;
    font-size: 25px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;

}
.newlogin .form-detail .inputbox{
    border-radius: 8px;
    padding: 9px;
    display: flex;
    align-items: center;
    border: 1px solid gray;
    background-color: white;
    cursor: pointer;
    margin: 20px 0px;
  }
  .newlogin .form-detail .inputbox .profileicon{
    color: rgb(192, 192, 192);
    background-color: white;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;

  }
  .newlogin .form-detail .inputbox .visibilityicon{
    color: rgb(192, 192, 192);
    background-color: white;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;

  }

  .newlogin .form-detail .inputbox input{
    border: none;
    outline: none;
    background-color: transparent;
    background-color: white;
    color: black;
    font-weight: 500;
    margin-left: 8px;
    font-family: "Montserrat", sans-serif;

  }
  .newlogin .form .form-detail .forgot-password{
    color: blue;
    margin-right: 90px;
    font-family: "Montserrat", sans-serif;

  }
  .newlogin .form .form-detail .new-account{
    margin-top: 18px;
    color: blue;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;

  }
  
  .newlogin .form .form-detail button{
    width: 100%;
    cursor: pointer;
    padding: 8px 0;
    margin-top: 8px;
    border: none;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
    font-family: "Montserrat", sans-serif;
  }
    
  @media screen and (max-width: 1250px) {

    .newlogin .loginpage .background .icon .logo{
        font-size: 17px;    
    }
    .newlogin .loginpage .background .icon p{
        font-size: 11px;
        margin-top: 5px;    
    }
    .newlogin .loginpage .background .welcome{
        margin-top: 25px;
    
    }
    .newlogin .loginpage .background .welcome .title{
        font-size: 15px;
    
    }
    .newlogin .loginpage .background .welcome p{
        font-size: 10px;
        margin-top: 5px;
    
    }
    .newlogin .form-detail .inputbox{
        padding: 9px;
        margin: 20px 0px;
      }
    }
       @media screen and (max-width: 950px) {
    
    .newlogin .loginpage .background .icon .logo{
        font-size: 15px;    
    }
    .newlogin .loginpage .background .icon p{
        font-size: 10px;
        margin-top: 4px;    
    }
    .newlogin .loginpage .background .welcome{
        margin-top: 22px;
    
    }
    .newlogin .loginpage .background .welcome .title{
        font-size: 13px;
    
    }
    .newlogin .loginpage .background .welcome p{
        font-size: 8px;
        margin-top: 4px;
    
    }
    .newlogin .form-detail .inputbox{
        padding: 8px;
      }
      .newlogin .form-detail .inputbox .profileicon{
        font-size: 15px;
    
      }
      .newlogin .form-detail .inputbox .visibilityicon{
        font-size: 15px;
    
      }
      .newlogin .form .form-detail button{
        font-size: 13px;
      }

      .newlogin .form-detail .inputbox input{
        font-size: 9px;
      }
      
      .newlogin .form .form-detail .new-account{
        margin-top: 15px;
        font-size: 12px;
    
      }
      .newlogin .form .form-detail .forgot-password{
       font-size: 10px;    
      }
    
  }
  @media screen and (max-width: 850px) {
    .newlogin .form-detail .inputbox{
        padding: 8px;
      }
      .newlogin .form-detail .inputbox .profileicon{
        font-size: 14px;
    
      }
      .newlogin .form-detail .inputbox .visibilityicon{
        font-size: 14px;
    
      }
      .newlogin .form .form-detail button{
        font-size: 9px;
      }
      .newlogin .form-detail .inputbox input{
        font-size: 9px;
      }
      .newlogin .loginpage .background .welcome p{
        font-size: 7px;
        margin-top: 5px;
    
    }
  }
  @media screen and (max-width: 650px) {

    .loginpage{
        grid-template-columns:  1fr 2fr;
        width: 95vw;
    
    }
  }
