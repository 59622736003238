.cart{
    max-width: 100vw;
}
.cart-content{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
}
.cart-content .left-cart .cart-item{
    display: grid;
    grid-template-columns: auto 2fr;
    margin-bottom:20px ;
    /* background-color: rgba(220, 220, 220, 0.744); */
    border: 1px solid grey;
    grid-gap: 10px 20px;
    padding: 10px 10px 50px 10px;
    position: relative;
    
}
.cart-content .left-cart .cart-item .remove{
    position: absolute;
    bottom:10px;
    right: 20px;
    color: blue;
    font-weight: bold;
    cursor: pointer;
    
}
.cart-content .left-cart .total-items{
    display: flex;
    justify-content: space-between;
}
.cart-content .left-cart .cart-item .cartItem-left{
    display: flex;
}
.cart-content .left-cart .cart-item .cartItem-left .prdt-img{
    width: 150px;
    height: 150px;
    border: 1px solid grey;
    
    
}
.cart-content .left-cart .cart-item .cartItem-left .prdt-img img{
    width: 100%;
    aspect-ratio: 1/1;
    padding: 5px;
    border-radius: 10px;
    
} 
.cart-content .left-cart .cart-item .cartItem-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    
}
.cart-content .left-cart .cart-item .cartItem-right .second-item{
    margin-top: 0;
    display: flex;
    
}
.cart-content .left-cart .cart-item .cartItem-right .second-item div{
    display: flex;
    margin-right: 15px;
    /* background-color: rgb(225, 225, 225); */
    width: auto;
    
    
}
.cart-content .left-cart .cart-item .cartItem-right .second-item p{
    padding: 0;
    margin: 0 10px 0 0;
    
    
}
.cart-content .left-cart .cart-item .cartItem-right .second-item div select{
    border: none;
    /* background-color: rgb(225, 225, 225); */
    
    
}

.cart-content .checkouts{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.cart-content .checkouts a{
    width: 100%;
    text-align: center;
}
.checkout-btn{
    width: 100%;
    text-align: center;
    padding: 8px;
    font-weight: 500;
    transition: all 0.5s;
}
.checkout-btn:hover{
    background-color: black;
    color: white;
    letter-spacing: 3px;
}
.cart-content .checkouts a button{
    width: 100%;
    text-align: center;
    padding: 8px;
    font-weight: 500;
    transition: all 0.5s;
}
.cart-content .checkouts a button:hover{
    background-color: black;
    color: white;
    letter-spacing: 3px;
}

@media screen and (max-width:950px) {
    .cart-content{
        grid-template-columns:1fr;
    }
    .cart-content .left-cart .cart-item{
        max-width: 100vw;
        display: grid;
        margin-bottom:20px ;
        /* background-color: rgba(220, 220, 220, 0.744); */
        grid-gap:0;
        padding: 10px 10px 50px 10px;
        position: relative;
        
    }
    .cart-content .left-cart .cart-item .cartItem-right .second-item{
        margin-top: 0;
        display: flex;
        flex-direction: column;
        
    }
}
@media screen and (max-width:650px) {
    .cart-content .left-cart .cart-item .cartItem-right{
        flex-direction: column;
        margin-left:10px;
    }
    .cart-content .left-cart .cart-item .cartItem-right div{
        margin-bottom: 5px;
    }

    .cart-content .left-cart{

    }
}
